import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 300.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">

<path d="M1324 2551 c-260 -41 -479 -258 -514 -509 -29 -211 12 -374 126 -501
47 -53 148 -125 209 -149 55 -22 185 -42 275 -42 85 0 274 31 308 50 9 6 37
19 62 30 55 25 103 52 111 63 4 5 21 1 40 -8 26 -14 37 -15 52 -6 10 7 27 9
38 6 13 -4 19 -2 19 9 0 13 -14 16 -84 16 -46 0 -90 2 -98 5 -8 3 -18 -5 -24
-22 -21 -55 -259 -123 -429 -123 -274 0 -477 128 -566 355 -20 52 -23 78 -23
195 -1 116 2 144 22 202 65 188 229 341 422 394 96 26 255 23 340 -5 129 -44
228 -131 282 -248 28 -59 32 -80 36 -177 4 -91 2 -115 -13 -142 l-17 -32 -276
-6 c-153 -4 -317 -10 -367 -14 l-90 -8 60 -60 c75 -75 128 -94 260 -94 107 0
236 29 360 81 110 45 77 48 -47 4 -175 -61 -344 -82 -441 -53 -37 11 -138 91
-127 101 5 5 200 14 485 23 l180 5 28 30 c26 28 27 33 27 145 0 105 -3 122
-31 193 -30 76 -76 137 -149 196 -31 25 -123 70 -170 84 -49 14 -215 21 -276
12z"/>
<path d="M1314 2252 c-52 -25 -107 -90 -126 -150 -9 -26 -15 -48 -14 -48 3 -4
459 15 462 19 8 8 -13 87 -30 112 -8 13 -32 36 -53 52 -32 25 -46 28 -117 31
-63 2 -90 -2 -122 -16z m190 -16 c46 -19 83 -53 92 -85 7 -24 6 -52 -1 -59 -2
-2 -88 -6 -191 -9 -130 -4 -185 -3 -180 5 3 6 1 12 -5 14 -26 9 49 94 115 130
38 21 125 23 170 4z"/>
<path d="M1911 1793 c-15 -54 -14 -57 17 -35 55 39 138 36 193 -7 13 -10 41
-21 61 -25 41 -7 95 -48 108 -80 6 -15 9 -16 9 -5 2 36 -67 92 -127 105 -8 1
-21 9 -30 17 -42 36 -105 59 -148 53 -24 -3 -46 -1 -49 4 -12 19 -24 9 -34
-27z"/>
<path d="M1833 1696 c-20 -18 -33 -41 -33 -55 l0 -25 20 22 c27 29 44 28 36
-1 -10 -43 12 -58 97 -64 54 -4 77 -3 77 6 0 7 -20 11 -54 11 -51 0 -54 2 -81
40 -24 35 -26 44 -17 66 15 31 15 34 0 33 -7 -1 -28 -15 -45 -33z"/>
<path d="M1803 1700 c-52 -31 -67 -78 -38 -116 14 -18 14 -17 5 9 -10 28 -13
74 -3 50 8 -22 23 -14 23 12 0 18 10 31 33 45 17 11 28 20 22 20 -5 0 -24 -9
-42 -20z"/>
<path d="M1900 1686 c0 -4 99 -66 106 -66 2 0 4 9 4 20 0 26 35 26 108 1 46
-16 53 -16 50 -3 -3 18 -83 42 -141 42 -35 0 -38 -2 -32 -21 7 -21 6 -21 -40
5 -47 26 -55 29 -55 22z"/>
<path d="M1150 1673 c-24 -9 -59 -55 -65 -84 -8 -34 14 -79 52 -109 22 -17 36
-20 68 -16 22 3 47 5 55 5 111 -6 148 -4 166 7 18 11 25 10 42 -2 14 -10 41
-14 79 -12 32 1 63 -1 69 -5 6 -3 27 -2 46 3 42 12 64 47 53 87 -4 15 -7 42
-8 58 -3 48 -19 66 -58 65 -37 -1 -62 0 -126 2 -23 0 -48 -4 -56 -10 -10 -9
-21 -9 -44 0 -41 15 -59 1 -72 -55 l-9 -42 -1 36 c-2 54 -27 71 -108 75 -38 1
-75 0 -83 -3z m68 -19 c15 -5 22 -14 22 -31 0 -21 -2 -23 -15 -13 -23 19 -64
4 -71 -27 -6 -20 -2 -31 16 -45 13 -10 29 -16 37 -13 7 3 20 8 28 11 12 5 14
2 7 -17 -7 -18 -18 -25 -45 -27 -87 -8 -116 115 -37 155 29 15 28 15 58 7z
m90 -64 l-4 -59 30 -3 c20 -2 32 -9 34 -20 3 -15 -4 -18 -50 -18 l-53 0 3 80
c3 73 5 80 24 80 19 0 20 -5 16 -60z m114 -20 c-3 -71 -6 -80 -23 -80 -17 0
-19 8 -19 80 0 76 1 80 23 80 21 0 22 -3 19 -80z m142 74 c9 -3 16 -15 16 -25
0 -21 -5 -22 -35 -10 -30 12 -55 -5 -55 -38 0 -41 24 -58 61 -44 25 10 29 9
29 -6 0 -46 -66 -56 -105 -16 -65 64 4 172 89 139z m124 -22 c3 -21 1 -21 -22
-10 -43 19 -43 -1 -1 -37 39 -33 40 -34 25 -62 -17 -31 -61 -43 -86 -22 -27
22 -17 38 21 32 39 -6 48 9 16 27 -28 15 -51 39 -51 55 0 46 92 62 98 17z"/>
<path d="M2180 1620 c0 -23 10 -26 28 -8 15 15 15 8 2 -28 -9 -24 -9 -25 5
-14 18 15 20 41 3 58 -18 18 -38 14 -38 -8z"/>
<path d="M2291 1604 c-2 -31 -48 -72 -95 -85 -59 -15 -67 -20 -21 -13 22 3 51
11 64 17 33 16 64 60 58 83 -4 17 -5 17 -6 -2z"/>
<path d="M2064 1602 c3 -5 19 -15 36 -22 16 -7 30 -17 30 -22 0 -5 -16 -22
-35 -39 -43 -38 -46 -56 -4 -32 39 23 79 69 79 91 -1 15 -2 15 -11 -1 -11 -18
-12 -18 -32 1 -24 22 -74 41 -63 24z"/>
<path d="M978 1073 c-60 -30 -84 -97 -55 -151 8 -13 32 -35 55 -50 49 -33 45
-59 -8 -47 -40 9 -34 -7 10 -25 39 -16 80 -6 80 20 0 13 -19 32 -54 54 -64 41
-78 63 -74 113 4 46 41 76 95 76 66 0 83 -10 83 -49 0 -35 16 -46 26 -19 5 12
10 10 26 -10 11 -14 21 -31 22 -38 1 -7 12 -26 24 -41 17 -23 22 -42 22 -90 0
-78 18 -106 65 -106 44 0 55 20 55 102 0 52 4 68 24 91 13 15 26 27 29 27 3 0
8 -24 12 -52 9 -68 26 -100 69 -133 68 -52 169 -57 219 -11 l25 23 11 -23 c10
-23 15 -24 109 -24 63 0 102 4 110 12 9 9 15 9 24 0 18 -18 73 -14 91 6 10 10
17 30 17 44 0 14 7 28 15 32 8 3 15 1 15 -4 0 -21 73 -80 112 -91 51 -13 119
-4 150 20 21 18 22 18 39 -3 14 -17 28 -21 81 -21 76 0 120 26 138 81 17 51 5
93 -37 136 -31 32 -34 38 -18 38 18 1 18 2 -4 21 -29 24 -84 23 -89 -2 -2 -11
15 -27 52 -48 69 -39 91 -69 83 -117 -11 -69 -74 -104 -157 -87 -44 8 -44 9
-48 53 -6 64 -25 71 -37 12 -10 -43 -15 -48 -51 -61 -54 -19 -106 -8 -151 32
-123 108 -55 299 107 299 71 0 90 -14 90 -68 0 -28 4 -43 10 -39 6 4 15 -7 21
-23 6 -17 28 -42 49 -57 46 -32 45 -57 -2 -48 -39 8 -31 -9 12 -27 39 -16 80
-6 80 20 0 14 -18 32 -55 55 -30 19 -59 44 -65 54 -15 28 -12 73 5 98 15 22
75 45 98 38 7 -2 24 -6 40 -9 21 -5 27 -12 27 -34 0 -15 5 -34 10 -42 7 -11
10 -4 10 27 0 35 -5 46 -27 60 -40 26 -110 24 -149 -6 l-32 -23 -44 26 c-76
45 -148 29 -223 -47 l-50 -51 -5 39 c-8 57 -16 65 -65 65 -53 0 -55 -5 -56
-118 -1 -124 -3 -130 -47 -129 -62 1 -72 17 -72 119 0 110 -8 128 -58 128 -42
0 -62 -14 -63 -45 0 -17 -2 -15 -10 7 -7 17 -24 32 -50 42 -52 21 -78 20 -141
-5 -43 -17 -56 -18 -75 -8 -39 21 -84 13 -115 -20 -33 -35 -58 -40 -65 -12 -8
30 -46 44 -105 38 -33 -3 -65 0 -84 8 -38 16 -71 15 -106 -2z m700 -24 c19 -7
22 -16 23 -61 l1 -53 13 35 c13 34 14 33 14 -49 1 -70 -1 -82 -14 -77 -8 3
-33 -1 -55 -9 -45 -16 -75 -13 -101 11 -29 26 -20 90 16 116 16 12 70 9 83 -4
6 -6 15 -9 19 -5 13 13 -32 36 -70 37 -54 0 -87 -35 -87 -93 0 -91 75 -126
158 -75 31 19 35 20 29 4 -4 -10 -7 -31 -7 -47 0 -25 -6 -33 -35 -45 -46 -19
-81 -17 -131 5 -58 26 -87 72 -92 142 -10 135 104 216 236 168z m-474 1 c27 0
36 -7 62 -50 l31 -50 34 49 c30 43 39 50 74 54 22 2 42 3 43 1 2 -1 -24 -39
-57 -84 l-61 -81 0 -79 0 -80 -40 0 -40 0 0 81 0 81 -55 80 c-30 44 -55 82
-55 85 0 3 7 2 16 -1 9 -3 30 -6 48 -6z m626 -122 l0 -128 60 0 60 0 0 -35 0
-35 -100 0 -100 0 0 163 0 162 40 0 40 0 0 -127z m240 -35 l0 -163 -40 0 -40
0 0 163 0 162 40 0 40 1 0 -163z"/>
<path d="M360 1051 c-5 -11 -10 -86 -11 -167 0 -131 2 -150 17 -161 13 -9 57
-13 154 -12 122 0 137 2 157 21 30 28 92 28 129 -1 28 -22 83 -28 100 -11 6 6
24 4 49 -5 102 -36 175 0 199 97 8 33 -11 83 -47 121 -21 22 -23 27 -9 27 14
1 12 5 -7 21 -16 13 -36 19 -55 17 -49 -5 -39 -33 24 -70 63 -38 85 -74 76
-123 -12 -61 -81 -94 -161 -78 -38 8 -40 11 -45 53 -4 40 -6 43 -17 25 -10
-18 -16 -10 -58 80 -62 135 -63 136 -86 165 l-20 25 7 -24 c3 -13 39 -91 79
-172 l72 -149 -43 0 c-38 0 -44 3 -50 25 -6 24 -10 25 -75 25 -65 0 -69 -1
-75 -25 -5 -21 -12 -25 -45 -25 -21 0 -39 2 -39 5 0 3 32 69 71 147 80 160 92
188 80 188 -5 0 -37 -56 -72 -125 l-63 -125 -54 5 c-60 4 -83 15 -34 15 52 0
77 43 52 91 -7 12 -21 19 -41 19 -18 0 -28 4 -24 10 4 6 12 10 18 9 7 -1 22
-1 35 0 29 1 48 45 33 78 -10 22 -16 23 -110 23 -89 0 -102 -2 -111 -19z m210
-28 l0 -33 -62 0 -63 0 0 -30 c0 -30 1 -30 53 -30 l52 0 0 -36 0 -35 -52 2
-51 3 -1 -32 -1 -32 63 0 62 0 0 -35 0 -35 -100 0 -100 0 0 163 0 162 100 0
100 0 0 -32z"/>
<path d="M2242 983 c-20 -8 -42 -59 -42 -98 0 -73 77 -108 151 -69 40 20 31
28 -18 15 -54 -15 -61 -14 -88 15 -29 32 -31 61 -7 90 26 31 56 37 92 19 39
-20 53 -10 21 16 -22 18 -79 24 -109 12z"/>
<path d="M721 893 c-8 -21 -16 -43 -18 -49 -3 -7 11 -11 37 -11 26 0 39 4 36
11 -3 6 -10 28 -17 49 -7 20 -15 37 -19 37 -3 0 -12 -17 -19 -37z"/>
<path d="M1535 593 c0 -35 -28 -52 -57 -34 -21 13 -22 13 -9 -2 7 -10 21 -17
31 -17 32 0 66 59 42 74 -4 2 -8 -7 -7 -21z"/>
<path d="M1428 286 c-2 -56 0 -72 10 -69 7 3 12 23 12 49 0 32 4 44 15 44 11
0 15 -12 15 -44 0 -40 3 -45 30 -55 18 -6 30 -6 30 0 0 5 -7 9 -15 9 -14 0
-35 29 -35 49 0 6 9 20 21 32 19 19 19 20 2 19 -46 -5 -63 0 -63 20 0 11 -4
20 -9 20 -5 0 -11 -33 -13 -74z"/>
<path d="M1380 330 c-7 -14 -7 -20 0 -20 5 0 10 -7 10 -15 0 -11 -7 -14 -24
-9 -15 3 -25 1 -29 -9 -8 -22 8 -40 31 -33 15 5 22 2 25 -11 3 -10 5 12 6 50
0 37 -1 67 -4 67 -2 0 -9 -9 -15 -20z"/>
<path d="M1011 306 c5 -6 9 -18 9 -28 0 -13 -8 -18 -31 -18 -19 0 -28 -4 -23
-11 3 -6 17 -9 30 -6 16 3 24 0 24 -9 0 -8 4 -14 9 -14 5 0 17 -3 27 -7 14 -5
13 -2 -4 15 -27 27 -28 46 -4 70 18 18 17 18 -14 18 -21 -1 -29 -5 -23 -10z"/>
<path d="M1115 314 c6 -3 9 -20 7 -39 -5 -38 11 -66 37 -64 13 1 13 2 -1 6
-27 7 -22 32 5 26 21 -5 21 -4 -3 16 -20 16 -23 23 -14 38 9 15 7 18 -15 19
-14 1 -21 0 -16 -2z"/>
<path d="M1306 298 c-25 -38 -47 -98 -36 -98 5 0 10 7 10 16 0 10 6 13 18 10
15 -5 17 -1 14 28 -3 26 1 37 19 50 19 13 20 16 6 16 -9 0 -23 -10 -31 -22z"/>
<path d="M1570 288 c-12 -18 -19 -25 -16 -15 4 14 1 18 -12 15 -9 -2 -17 -12
-17 -23 0 -16 6 -20 28 -18 30 2 36 -11 11 -25 -11 -6 -7 -9 18 -9 18 0 27 2
21 5 -23 7 -14 30 10 25 21 -5 21 -4 -3 16 -22 17 -23 23 -12 41 20 33 -4 23
-28 -12z"/>
<path d="M1814 303 c-9 -10 -26 -17 -38 -18 -14 0 -21 -6 -21 -20 0 -15 6 -20
27 -18 18 1 28 -4 31 -18 3 -12 13 -19 29 -18 14 0 17 3 8 6 -19 7 -40 33 -40
50 0 7 9 22 20 33 11 11 16 20 11 20 -5 0 -17 -8 -27 -17z"/>
<path d="M1893 302 c23 -26 21 -58 -5 -76 -23 -16 -13 -21 16 -10 22 9 22 89
0 98 -26 10 -28 7 -11 -12z"/>
<path d="M1207 293 c-4 -7 -15 -10 -25 -7 -11 3 -10 0 6 -12 26 -20 29 -51 5
-57 -10 -3 -3 -5 15 -6 36 -1 40 13 18 64 -8 19 -15 25 -19 18z"/>
<path d="M1064 275 c-7 -18 3 -35 22 -35 8 0 14 10 14 25 0 27 -27 35 -36 10z"/>
<path d="M1638 273 c26 -19 28 -38 5 -52 -17 -10 -17 -10 -1 -11 10 0 22 8 27
19 8 14 5 23 -10 40 -11 11 -25 21 -32 20 -7 0 -2 -7 11 -16z"/>
<path d="M1844 275 c-7 -18 3 -35 22 -35 8 0 14 10 14 25 0 27 -27 35 -36 10z"/>
<path d="M1954 276 c-8 -20 -1 -47 11 -49 6 -1 11 13 13 31 3 32 -14 45 -24
18z"/>
<path d="M2016 283 c-3 -3 -6 -19 -6 -34 0 -21 4 -27 15 -23 8 4 15 17 15 29
0 25 -12 39 -24 28z"/>
<path d="M1707 266 c-9 -24 18 -56 47 -56 19 0 19 1 -4 13 -13 7 -27 23 -30
37 -5 22 -7 23 -13 6z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
